// クリニック関連処理
var ZENJINKAI_FORM = ZENJINKAI_FORM || {};
ZENJINKAI_FORM.init = function() {

  // デフォルト値の定義 - START /////////////////
  // デフォルト値の定義 - END ///////////////////


  // Private Function - START ///////////////////
  function convertData($form) {

  }

  function setupForm($form) {
    var $formWrap = $form.parent();

    // 全角→半角変換
    $form.find('.js_form_field_convertZ2H').each(function() {
      var $input = $(this);
      $input.on('change', function() {
        $input.val(ZENJINKAI_FORM.COMMON.convertZen2Han($input.val()));
      });
    });

    // エラー表示用フィールドの取得
    // ※エラー表示用のDOMには、対になるフィールドのIDをベースに、js_error_[フィールドID]の形式でclassがセットされている
    var fieldsets = {};
    $form.find('.js_form_field').each(function() {
      var $field = $(this);
      var name      = $field.attr('name');
      if ($field.attr('id')) {
        var errorSlct   = '.js_error_' + $field.attr('id');
        var $error      = $form.find(errorSlct);
        fieldsets[name] = $error;
      }
    });

    // submit時の処理
    $form.on('submit', function(e) {
      e.preventDefault();

      var data = $form.serialize();
      var url  = $form.attr('action');


      // GAイベントトラッキングのための処理1 - START
      var $gaCheck = $form.find('.js_GA_conversion.js_GA_conversion_async');
      // GAイベントトラッキングのための処理1 - END

      $.ajax({
        url: url,
        type: "POST",
        data: data,
        dataType: "json",
        success : function(result) {

          // 成功（入力エラーなし）
          if (result.status === 1) {
            // 送信完了メッセージの表示
            $formWrap.html(result.message);

            // GAイベントトラッキングのための処理2 - START
            if ($gaCheck.length > 0) {
              ENGINE.Conversion.Func.send($gaCheck);
            }
            // GAイベントトラッキングのための処理2 - END

          // 失敗（入力エラーあり）
          } else {
            // エラーメッセージの表示
            // 入力フィールド分LOOP
            for(var fieldName in fieldsets) {

              // エラーがセットされている場合
              if (result.errors[fieldName]) {
                fieldsets[fieldName].html(result.errors[fieldName].join('<br>'));

              // エラーがない場合
              } else {
                fieldsets[fieldName].empty();
              }
            }

          }

        },
        error: function() {
        }
      });

      var $anchor = $('#anchor-contact');
      if ($anchor.length > 0) {

        var position = $anchor.offset().top - 50;

        $('body,html').animate({scrollTop:position}, 200, 'easeOutQuart');

      }


      return false;
    });
  }
  // Private Function - END /////////////////////

  return {
    execute: function() {
      // お問い合わせフォームの制御
      this.initContact();
      // 見学申し込みフォームの制御
      this.initVisitApplicate();
    },
    initContact: function() {
      $('.js_contact').each(function() {
        var $form = $(this);
        setupForm($form);
      });
    },
    initVisitApplicate: function() {
      $('.js_visit_applicate').each(function() {
        var $form = $(this);
        setupForm($form);
      });
    }
  }
};

/* utility関数
 --------------------------------------------*/
ZENJINKAI_FORM.COMMON = {

  /**
   * trim関数
   * 
   */
  trim: function(str) {
    return (str + '').replace(/(^[\s　]+)|([\s　]+$)/g, "");
  },

  /**
   * 未入力チェック
   *
   */
  empty: function(str) {
    if (str) {
      if (ZENJINKAI_FORM.COMMON.trim(str) !== '') {
        return false;
      }
    }

    return true;
  },

  /**
   * 配列かどうかのチェック
   * 
   */
  isArray: function(arr) {
      return Object.prototype.toString.call(arr) === '[object Array]';
  },

  /**
   * 半角カタカナを全角カタカナに変換 
   *
   */
  convertHankana2Zenkana: function (str) {
    if (ZENJINKAI_FORM.COMMON.empty(str)) {
      return str;
    }

    var kanaMap = {
      'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
      'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
      'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
      'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
      'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
      'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
      'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
      'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
      'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
      'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
      'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
      'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
      'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
      'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
      'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
      'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
      'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
      'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
      '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
    };

    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');

    return str.replace(reg, function (match) {
        return kanaMap[match];
      }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
  },

  /**
   * 全角から半角に置き換え
   *
   * 全角チルダ、全角波ダッシュ共に半角チルダに変換
   * 全角ハイフン、全角ダッシュ、全角マイナス記号は半角ハイフンに変換
   * 長音符は半角ハイフンに含めない（住所の地名等に使用される為）
   *
   * 今は良いがUnicode 8.0で波ダッシュの形が変わるみたいなので、波ダッシュを変換に
   * 含めるべきかどうかは検討が必要
   *
   */
  convertZen2Han: function(str) {
    // 英字変換
    str = str.replace(/[Ａ-Ｚａ-ｚ]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });

    // 数字変換
    str = str.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });

    // 記号変換
    var reg = /[！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;
    str = str.replace(reg, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      }).replace(/[‐－―−]/g, '-');

    // チルダ変換
    str = str.replace(/[～〜]/g, '~');
    // スペース変換
    str = str.replace(/　/g, ' ');

    return str;
  },

  /**
   * 半角カタカナを全角カタカナに変換 
   *
   */
  convertJIS: function (str) {
    if (ZENJINKAI_FORM.COMMON.empty(str)) {
      return str;
    }

    var jisMap = {
      '㈱': '(株)', '㈲': '(有)', '㈹': '(代)'
    };

    var reg = new RegExp('(' + Object.keys(jisMap).join('|') + ')', 'g');

    return str.replace(reg, function (match) {
        return jisMap[match];
      });
  }
}
